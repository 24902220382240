import React, { useEffect, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import FlexContentHandler from 'components/shared/FlexContentHandler'
import { Helmet } from 'react-helmet'

interface PageTemplateProps {
  data: {
    page: GatsbyTypes.WpOpleiding
    reviews: {
      nodes: GatsbyTypes.WpReview[]
    }
  }
  location?: any
  prefix?: string
}

export interface EducationContextProps {
  data?: GatsbyTypes.WpOpleiding,
}

export const EducationContext = React.createContext({})

const PageTemplate: React.FC<PageTemplateProps> = ({
  data: { page: data, reviews },
  location = {},
  prefix = 'Opleiding_Flexcontent',
}) => {
  const [reviewData, setData] = useState<any>([])
  const relatedReviews = () => {

    const related = reviews?.nodes?.filter((r) => {
      if (r?.recap?.relation) {
        const relatedData = r?.recap?.relation.filter((review) => review?.databaseId === data?.databaseId)

        if (relatedData?.length > 0) {
          return relatedData
        }
      }

      return false
    })

    return related
  }

  useEffect(() => {
    const d = relatedReviews()
    setData(d)
  }, [])


  return (
    <EducationContext.Provider value={data}>
      <Layout type="education">
        <SEO seo={data.seo} />
        <Helmet>
          <script type="application/ld+json">
            {`{
                "@context": "https://schema.org",
                "@type": "Course",
                "name": "${data.title}",
                "description": "${data.previeweducation?.description || data.title
              }",
                "provider": {
                    "@type": "Organization",
                    "name": "Academie voor Psychodynamica",
                    "sameAs": "https://www.psychodynamica.nl/"
                }
              }`}
          </script>
          {reviewData && (
            <script type="application/ld+json">
              {`
            {
              "@context":"https://schema.org",
              "@type":"ItemList",
              "itemListElement":[
                ${reviewData.map(
                (rev: any) =>
                  `{
                    "@context": "https://schema.org",
                    "@type": "Review",
                    "name": "${rev.title}",
                    "author": {
                      "@type": "Person",
                      "name": "${rev.recap?.person || 'Onbekend'}"ww
                    },
                    "itemReviewed": {
                      "@context": "https://schema.org/",
                      "@type": "Course",
                      "name": "${rev.recap?.relation?.[0]?.title}",
                      "description": "${
                  // @ts-ignore
                  rev.recap?.relation?.[0]?.previeweducation?.description?.replace(
                    /<[^>]*>/g,
                    ''
                  ) ||
                  rev.recap?.relation?.[0]?.title ||
                  ''
                  }",
                      "provider": {
                          "@type": "Organization",
                          "name": "Academie voor Psychodynamica",
                          "sameAs": "https://www.psychodynamica.nl/"
                      }
                    },
                    "description": "${rev.recap?.description?.replace(/<[^>]*>/g, '') ||
                  rev.title ||
                  ''
                  }",
                    "provider": {
                        "@type": "Organization",
                        "name": "Academie voor Psychodynamica",
                        "sameAs": "https://www.psychodynamica.nl/"
                    }
                  }`
              )}
              ]
            }
            `}
            </script>
          )}
        </Helmet>
        <FlexContentHandler
          prefix={prefix}
          fields={data.flexContent}
          location={location}
          data={data}
        />
      </Layout>
    </EducationContext.Provider>
  )
}

export const pageQuery = graphql`
  query opleidingById($pageId: String!) {
    page: wpOpleiding(id: { eq: $pageId }) {
      ...generalOpleidingFragment
    }
    reviews: allWpReview(sort: { order: DESC, fields: date }) {
      nodes {
        ...generalReviewFragment
      }
    }
  }
`

export default PageTemplate
